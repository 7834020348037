import { useState } from 'react';
import './App.css';
import logo from './logo.png';
import back from './back.png';
import axios from 'axios';

const App = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ email: '', name: '' });

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = axios.post(
        'https://testdev-ai-website-backend.onrender.com/waitlist',
        {
          name: formData.name,
          email: formData.email,
        },
        {
          headers: {
            'Content-Type': 'application/json', // Ensure the correct content type
          },
        }
      );
      console.log('Form submitted successfully:', response.data);
      alert('Joined waitlist successfully!');
      setShowForm(false); // Optionally hide the form after successful submission
    } catch (error) {
      if (error.response) {
        console.error('Error submitting form:', error.response.data);
      } else {
        console.error('Error submitting form:', error.message);
      }
    }
  };

  const renderHeader = () => {
    return (
      <div>
        <header>
          <img className="Logo" src={logo} alt="Logo da Porto" />
          <p className='Slogan'>Seamless automated test generation tool</p>
          <nav>
            <ul>
              {/* Navigation links */}
            </ul>
          </nav>
        </header>
      </div>
    );
  };

  const renderMainBody = (handleButtonClick) => {
    return (
      <div>
        <main>
          <section className="text">
            <h1>Generate unit and integration tests with a single command!</h1>
            <p><strong>Testdev.ai</strong> is a <strong>Visual Studio Code</strong> extension that creates meaningful automated tests for your <strong>TypeScript</strong> projects with a single command.</p>
            <button onClick={handleButtonClick}>
              Join waitlist
            </button>
          </section>
          <img className="MainImage" src={back} alt="A man and a woman behind a monitor, keyboard, and mouse" />
        </main>
      </div>
    );
  };

  const renderWaitlistForm = (handleButtonClick, handleFormChange, handleFormSubmit) => {
    return (
      <div className='WaitListForm'>
        <div className="page">
          <div>
            <h1>Join the waitlist!</h1>
            <p id="subtitle">Just add your info below.</p>
          </div>
          <form id="my-form" onSubmit={handleFormSubmit}>
            <fieldset>
              <div className="fieldset-wrapper">
                <div className="input-wrapper">
                  <label htmlFor="solicitant-email">Email</label>
                  <input
                    type="email"
                    id="solicitant-email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormChange}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <label htmlFor="solicitant-name">Name</label>
                  <input
                    type="text"
                    id="solicitant-name"
                    name="name"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
            </fieldset>

            <footer className="form-buttons">
              <input type="submit" form="my-form" value="Send" />
              <button className="BackButton" onClick={handleButtonClick}>
                Go back
              </button>
            </footer>
          </form>
        </div>
      </div>
    );
  };

  const renderThreeCards = () => {
    return (
      <div id="three-cards">

        <div className="card">
          <p>Automated Test Generation</p>
          <div className="content">
            Generate high-quality unit and integration tests with Testdev.ai. Speed up your testing process with minimal effort.
            {/* <button><img src="images/plus.svg" /></button> */}
          </div>
        </div>

        <div className="card">
          <p>Context-Aware Test Structure</p>
          <div className="content">
            Testdev.ai generates tests based on your project's context, creating a seamless fit within your codebase. Say goodbye to manual test setup and disruptions.
            {/* <button><img src="images/down-arrow.svg" /></button> */}
          </div>
        </div>

        <div className="card">
          <p>Minimize Context Switching</p>
          <div className="content">
            Stay focused on coding while Testdev.ai automates test creation. By reducing context switching, you can increase productivity and maintain your workflow's momentum.
            {/* <button><img src="images/down-arrow.svg" /></button> */}
          </div>
        </div>

      </div>
    )
  };

  return (
    <div className='Body'>
      <div className="page">
        {renderHeader()}
        {!showForm ? renderMainBody(handleButtonClick) : renderWaitlistForm(handleButtonClick, handleFormChange, handleFormSubmit)}
        {!showForm ? renderThreeCards() : null}
        <footer className='Footer'>
          <p>If you have any questions, send an email to <a href="mailto:cs.eduardo@icloud.com" target="_blank">cs.eduardo@icloud.com</a></p>
        </footer>
      </div>
    </div>
  );
};

export default App;
